@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Open Sans', sans-serif;
}

@layer components {
  .navItems {
    @apply cursor-pointer px-4 sm:mx-6 first:pl-0 last:pr-0;
  }
  .navLinks {
    @apply text-white uppercase text-lg font-normal hover:text-slate-200 transition-all;
  }
  .resultsPara {
    @apply px-8 md:px-0 md:w-[60%] mx-auto hyphens font-medium;
  }
}

.heroSection__img {
  background: url('./assets/p 2.png') center center no-repeat;
  /* as image is for 1080width as we stretch more it will become flat in bottom */
  background-size: 100% 100%;
  /* background-size: contain; */
  height: 42vh;
}
/* @media (min-width: 1090px) {
  .heroSection__img {
    background-size: auto;
  }
} */

.hyphens {
  hyphens: auto;
}

.featureSection__img {
  background: url('./assets/p 6.png') center center no-repeat;
  background-size: 100% 100%;

  /* height: 100vh; */
}

.ingredientsSection__img {
  background: url('./assets/k 1.jpg') center center no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}
